[data-reset-progress] {
  @apply flex flex-col;
  [data-trigger] {
    @apply mt-3 inline-block self-end text-sm opacity-80 transition hover:underline hover:opacity-100;
  }
  [data-overlay] {
    @apply fixed inset-0 z-30 bg-black/20 backdrop-blur-sm;
  }
  [data-content] {
    @apply fixed left-[50%] top-[50%] z-50 max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] border border-gray-700/50 bg-gray-800 p-[25px] shadow-2xl shadow-black/40 focus:outline-none;
  }
  [data-title] {
    @apply border-b border-gray-700/50 pb-8 pr-10 text-lg;
  }
  [data-actions] {
    @apply flex items-center space-x-2 pt-6;
  }
  [data-button-reset] {
    @apply rounded-md bg-rose-500 px-4 py-2 font-medium text-white transition disabled:cursor-wait disabled:opacity-60 hover:bg-rose-600;
  }
  [data-button-cancel] {
    @apply rounded-md bg-gray-700 px-4 py-2 font-medium text-white transition hover:bg-gray-600;
  }
  [data-button-close] {
    @apply absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full opacity-60 transition focus:shadow-[0_0_0_2px] focus:outline-none hover:opacity-100;
    svg {
      @apply w-5;
    }
  }
}
